import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import Team from "../components/team"
import Image from "../utils/use-gatsby-local-image.js"

export default class About extends React.Component {
  render() {
    return (
      <>
        <Head title={"About"} />
        <Layout activePage="About">
          <div className="mb-5 flex items-center">
            <div className="w-full md:w-1/4 flex flex-col">
              <h1 className="mb-6">
                <span className="text-green-500">The Story </span>
                <span className="normalize">and the team</span>
              </h1>
              <div className="text-2xl italic">
                <p>"I realized I was looking at what some random
                person said on Twitter before saying good morning to my wife."
                </p>
                <p className="mt-3">
                ~ James Kelly, FaithTech CEO and Digital Sabbath Founder
                </p>
              </div>
            </div>
            <div className="md:block md:w-3/4 diagonal-cut">
              <Image
                fileName="james.png"
                alt="A man holding a microphone"
                className="h-100"
              />
            </div>
          </div>
          <div className="m-3 my-9">
            <p className="my-2">
              The Digital Sabbath started in 2016 when James Kelly, founder of FaithTech, realized 
              how much time he was spending on his phone and other digital technology. Inspired by the 
              Sabbath in the Bible, where God takes a day of rest after creating the world in 6 days, 
              James started by taking one day off from all digital technology each week and called it a
               &quot;Digital Sabbath&quot;. FaithTech then created a website for The Digital Sabbath to spread the
                word and invite anyone who wanted to join him. The team at FaithTech also built an email 
                system that sent reminder emails the day before your set Digital Sabbath.
            </p>
            <p className="my-2">
              Since then, the Digital Sabbath was cited in the New York Times, and thousands of people 
              have taken a Digital Sabbath and found rest, peace, and as one person put it, &quot;it felt like 
              I was walking into fresh water&quot;. Recently, the new Digital Sabbath Team 
              (from FaithTech Waterloo) has imprved the website and reminder email system, and is looking into how
               to make The Digital Sabbath even better.
            </p>
          </div>
        </Layout>
      </>
    )
  }
}
