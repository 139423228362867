import React from "react"
import { withPrefix } from 'gatsby';


export default class TeamMate extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showBio: false };
    }
    render() {
        return (
            <div className="teammate-wrapper mt-4 align-middle block lg:inline-block lg:mt-0">
               <button onClick={() => this.setState({ showBio: !this.state.showBio })}> 
               <div
               style={{"background-image":`url(${withPrefix('images/team/'+this.props.image)}`}} 
                className={`teammate-container ${this.state.showBio?'opacity-50':''}`}>
                </div>
                </button>
                
                <div className={`block align-top md:inline-block ${this.state.showBio? 'lg:inline-block':'lg:hidden'} relative lg:absolute bg-gray-50 teammate-bio w-100 max-w-sm rounded-2xl shadow-md  ml-5 lg:ml-0 p-2 ${this.props.displayPosition}`}>
                    {this.props.bio}
                </div>
            </div>);
    }
}